import React from 'react';
import { observer } from 'mobx-react-lite';

import CloseDayPopup from 'src/components/common/popups/close-day-popup';
import SelectWorkersPopup from 'src/components/common/popups/select-workers-popup';

const AllPopups = observer(() => {
  return (
    <>
      <CloseDayPopup />
      <SelectWorkersPopup />
    </>
  );
});

export default AllPopups;
