import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Radiobox from 'src/components/ui/radiobox';
import TooltipTrigger from 'src/components/ui/tooltip-trigger';
import { ReactComponent as QRCodeIcon } from 'src/assets/icons/qr-code.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/small-warning.svg';

import globalAppStore from 'src/stores/global-app-store';
import cremationStore from 'src/stores/sorting-center/cremation-store';

import { ICremation } from 'src/interfaces/cremations.interface';
import {
  getFullName,
  getLifeDates,
  getParsedDate,
  getParsedTime,
  getDifferenceDay,
} from 'src/utils';

import './sorting-table-row.scss';

const SortingTableRow = observer(
  ({
    cremation: { id, date, deceased, showDate, scanned, hallName, comments, urgent, crematorium },
  }: {
    cremation: ICremation;
  }) => {
    const isDayClosed = cremationStore.isDayClosed;
    const renderCrematoriumsRadiobox = () => (
      <div className="sorting-table-row__cremations_radiobox">
        {globalAppStore.crematoriumItems.map((c) => (
          <Radiobox
            key={c.id}
            idx={`${id}_${c.id}`}
            name={`${id}_${c.name}`}
            isChecked={c.id === crematorium?.id}
            isDisabled={isDayClosed}
            noLabel
            onChange={cremationStore.patchCremation}
          />
        ))}
      </div>
    );

    const isSorted = () =>
      crematorium && !!globalAppStore.crematoriumItems.find((c) => c.id === crematorium?.id);

    const statusClasses = classNames(
      'sorting-table-row__status',
      isDayClosed && 'sorting-table-row__status_disabled'
    );

    const isUrgentOrderRescheduled = getDifferenceDay(showDate, date) > 0 && urgent;
    const isOrderMovedMultipleTimes = getDifferenceDay(showDate, date) > 1;

    const isWarning = !isDayClosed && (isUrgentOrderRescheduled || isOrderMovedMultipleTimes);

    const getTooltipText = () => {
      if (isUrgentOrderRescheduled) {
        return 'Срочный заказ уже переносился на завтра';
      }

      return 'Заказ переносился на завтра более двух раз';
    };

    return (
      <tr
        className={classNames(
          'sorting-table-row',
          isSorted() && 'sorting-table-row_marked',
          isWarning && 'sorting-table-row_warning'
        )}
      >
        <td className="sorting-table-row__cell">
          <span className="sorting-table-row__date">
            <span className="table__text-wrapper">
              <span className="table__text">{getParsedDate(date)}</span>
              <span className="table__small-text">{getParsedTime(date)}</span>
            </span>
            {isWarning ? (
              <TooltipTrigger text={getTooltipText()}>
                <WarningIcon className="sorting-table-row__warning-icon" height={16} width={16} />
              </TooltipTrigger>
            ) : null}
          </span>
        </td>

        <td className="sorting-table-row__cell">
          <span className="sorting-table-row__deceased-wrapper">
            <TooltipTrigger text={scanned ? 'Отсканировано' : 'Не отсканировано'}>
              <QRCodeIcon
                className={classNames('sorting-table-row__icon', {
                  'sorting-table-row__icon_scanned': scanned,
                })}
              />
            </TooltipTrigger>

            <span className="sorting-table-row__deceased">
              <span className="sorting-table-row__deceased-name">{getFullName(deceased)}</span>
              <span className="sorting-table-row__deceased-dod">{getLifeDates(deceased)}</span>
            </span>
          </span>
        </td>

        <td className="sorting-table-row__cell">
          <span className="sorting-table-row__comments">{hallName}</span>
        </td>

        <td className="sorting-table-row__cell">
          <span className="sorting-table-row__comments">{comments}</span>
        </td>

        <td className="sorting-table-row__cell">{renderCrematoriumsRadiobox()}</td>
        <td className="sorting-table-row__cell">
          <div className={statusClasses}>{urgent && 'C'}</div>
        </td>
      </tr>
    );
  }
);

export default SortingTableRow;
