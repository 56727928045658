import React from 'react';
import classNames from 'classnames';

import Button from 'src/components/ui/button';
import { ReactComponent as ArrowsChangeIcon } from 'src/assets/icons/arrows-change.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';

import { getListWOrkers } from 'src/utils';
import { ListDocumentsTableProps } from './list-documents-table.props';

import './list-documents-table.scss';

const ListDocumentsTable = React.forwardRef<HTMLDivElement, ListDocumentsTableProps>(
  (
    {
      handleDownloadReportButtonClick,
      handleSelectWorkersButtonClick,
      listCrematoriums,
    }: ListDocumentsTableProps,
    ref
  ) => (
    <div className="list-documents-table" ref={ref}>
      <table className="table table_list-documents">
        <thead className="list-documents-table__header">
          <tr className="table__row table__row_no-border">
            <th className="table__header-cell">Крематорий</th>

            <th className="table__header-cell">Количество тел</th>

            <th className="table__header-cell">Бригада</th>

            <th className="table__header-cell">Действия</th>
          </tr>
        </thead>

        <tbody className="list-documents-table__body">
          {listCrematoriums.map(({ id, name, workers, cremationsCount }, index, { length }) => {
            const brigadeClasses = classNames(
              'list-documents-table__brigade',
              !workers?.length && 'list-documents-table__brigade_not-selected'
            );

            const rowClasses = classNames(
              'table__row',
              index + 1 === length && 'table__row_no-border'
            );

            const selectedWorkersIds = workers.map(({ id }) => id);

            return (
              <tr className={rowClasses} key={id}>
                <td className="list-documents-table__cell">
                  <span className="table__text">{name}</span>
                </td>

                <td className="list-documents-table__cell">
                  <span className="table__text">{cremationsCount}</span>
                </td>

                <td className="list-documents-table__cell">
                  <span className="list-documents-table__brigade-wrapper">
                    <Button
                      className="list-documents-table__choose-button"
                      icon={<ArrowsChangeIcon className="list-documents-table__icon" />}
                      onClick={() => handleSelectWorkersButtonClick(id, selectedWorkersIds)}
                      size="lg"
                      theme="ghost"
                    />

                    <span className="list-documents__divider" />

                    <span className={brigadeClasses}>
                      {workers?.length > 0 ? getListWOrkers(workers) : 'Не выбрана'}
                    </span>
                  </span>
                </td>

                <td className="list-documents-table__cell">
                  <Button
                    icon={
                      <DownloadIcon className="list-documents-table__icon list-documents-table__icon_hover" />
                    }
                    onClick={() => handleDownloadReportButtonClick(id, name ?? '')}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
);

export default ListDocumentsTable;
