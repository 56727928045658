import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import ListDocumentsInfo from '../list-documents-info';
import ListDocumentsTable from '../list-documents-table';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

import closedDayStore from 'src/stores/sorting-center/closed-day-store';
import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import { IListDocumentsProps } from './list-documents.props';

import './list-documents.scss';

const ListDocuments = observer(({ isCollapsed, onClick }: IListDocumentsProps) => {
  const listCrematoriums = closedDayStore.listClosedCrematoriums;

  const [isAnimated, setIsAnimated] = React.useState(false);
  const [style, setStyle] = React.useState({
    height: '',
  });

  const listDocumentsInfoRef = React.useRef<null | HTMLDivElement>(null);
  const listDocumentsTableRef = React.useRef<null | HTMLDivElement>(null);
  const wrapperRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    const wrapperRefEl = wrapperRef.current;
    const listDocumentsTableHeight = listDocumentsTableRef.current?.clientHeight;
    const listDocumentsInfoHeight = listDocumentsInfoRef.current?.clientHeight;

    if (style.height && listDocumentsTableHeight && !isAnimated && !isCollapsed) {
      setStyle({ height: `${listDocumentsTableHeight}px` });
      setIsAnimated(true);
    }

    if (style.height && listDocumentsInfoHeight && !isAnimated && isCollapsed) {
      setStyle({ height: `${listDocumentsInfoHeight}px` });
      setIsAnimated(true);
    }

    const handleTransitionEnd = (e: any) => {
      if (isAnimated && e?.target?.tagName === 'DIV' && e?.propertyName === 'height') {
        setStyle(() => ({ height: '' }));
        setIsAnimated(false);
        wrapperRefEl?.removeEventListener('transitionend', handleTransitionEnd);
      }
    };

    if (wrapperRefEl && isAnimated) {
      wrapperRefEl.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => wrapperRefEl?.removeEventListener('transitionend', handleTransitionEnd);
  }, [style, isAnimated, isCollapsed]);

  const handleButtonClick = () => {
    if (isAnimated) return;

    setStyle({ height: `${wrapperRef.current?.clientHeight}px` });
    onClick();
  };

  const handleSelectWorkersButtonClick = (id: string, selectedWorkersIds: string[]) => {
    popupStore.showPopup(POPUP_NAMES.SELECT_WORKERS_POPUP, {
      listWorkers: closedDayStore.listWorkers,
      onSubmit: (listSelectedWorkersIdx: string[]) =>
        closedDayStore.addWorkers(id, listSelectedWorkersIdx),
      selectedWorkersIds,
    });
  };

  const buttonClasses = classNames(
    'list-documents__toggle-button',
    isCollapsed && 'list-documents__toggle-button_up'
  );

  return (
    <div className="list-documents">
      <div className="list-documents__toggle-button-wrapper">
        <Button
          className={buttonClasses}
          icon={<ArrowIcon />}
          isDisabled={isAnimated}
          onClick={handleButtonClick}
          size="sm"
        />
      </div>

      <div className="list-documents__wrapper" style={style} ref={wrapperRef}>
        {isCollapsed ? (
          <ListDocumentsInfo listCrematoriums={listCrematoriums} ref={listDocumentsInfoRef} />
        ) : (
          <ListDocumentsTable
            handleDownloadReportButtonClick={closedDayStore.getExport}
            handleSelectWorkersButtonClick={handleSelectWorkersButtonClick}
            listCrematoriums={listCrematoriums}
            ref={listDocumentsTableRef}
          />
        )}
      </div>
    </div>
  );
});

export default ListDocuments;
