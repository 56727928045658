import { makeAutoObservable, reaction } from 'mobx';
import { EventSourcePolyfill } from 'event-source-polyfill';

import closedDayStore from 'src/stores/sorting-center/closed-day-store';
import cremationStore from 'src/stores/sorting-center/cremation-store';
import farewellStore from 'src/stores/control-room/farewell-store';
import filtersStore from 'src/stores/sorting-center/filters-store';
import mapStore from 'src/stores/map-store';

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'src/helpers/localStorageHelper';
import { APP_LOCALSTORAGE_JWT_KEY } from 'src/constants';
import { IFarewell } from 'src/interfaces/farewell.interface';
import { ICrematorium } from 'src/interfaces/crematorium.interface';

const decodeRawToken = (tokenRaw: string) => {
  try {
    const payload = tokenRaw.split('.')[1];
    const decoded = atob(payload);

    return JSON.parse(decoded);
  } catch (e) {
    console.error('Failed to process token');
  }
};

class GlobalAppStore {
  init() {
    this.checkToken();

    if (!this.token) return;

    const Authorization = `Bearer ${this.token}`;

    try {
      this.eventSource = new EventSourcePolyfill(`${(window as any).API_URL}/stream`, {
        headers: {
          Authorization,
        },
      });
      this.eventSource.addEventListener('farewells', this.handleFarewells);
      this.eventSource.addEventListener('halls', this.handleHalls);
      this.eventSource.addEventListener('crematoriums', this.handleCrematoriums);
      this.eventSource.addEventListener('cremations', this.handleCremations);
      this.eventSource.addEventListener('open', this.handleOpen);
      this.eventSource.addEventListener('closedDays', this.handleClosedDays);
    } catch (e) {
      console.log(e);
    }
  }

  token = getLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY);

  isLoading = false;

  farewells: IFarewell[] = [];

  crematoriumItems: ICrematorium[] = [];

  eventSource: any;

  isConnectionEstablished: boolean = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => token && this.init()
    );

    reaction(
      () => this.crematoriums,
      (items) => filtersStore.setCrematories(items)
    );

    this.handleOpen = this.handleOpen.bind(this);
    this.handleCrematoriums = this.handleCrematoriums.bind(this);
  }

  get crematoriums() {
    return this.crematoriumItems;
  }

  get role() {
    return this.token ? decodeRawToken(this.token)?.pld?.role || '' : '';
  }

  setToken(token: string) {
    this.token = token;
    setLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY, token);
  }

  setCrematoriums(crematoriums: ICrematorium[]) {
    this.crematoriumItems = crematoriums;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsConnectionEstablished(isConnectionEstablished: boolean) {
    this.isConnectionEstablished = isConnectionEstablished;
  }

  logOut() {
    removeLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY);
    this.setToken('');
    this.eventSource?.removeEventListener('open', this.handleOpen);
    this.eventSource?.removeEventListener('farewells', this.handleFarewells);
    this.eventSource?.removeEventListener('halls', this.handleHalls);
    this.eventSource?.removeEventListener('crematorium', this.handleCrematoriums);
    this.eventSource?.removeEventListener('cremation', this.handleCremations);
    this.eventSource?.removeEventListener('closedDays', this.handleClosedDays);
    this.setIsConnectionEstablished(false);
    this.eventSource?.close();
  }

  checkToken() {
    const TOKEN = process.env.REACT_APP_TOKEN;

    if (!this.token && TOKEN) {
      this.setToken(TOKEN);
    }
  }

  handleFarewells(event: any) {
    const result = JSON.parse(event.data);
    farewellStore.setApiFarewells(result);
  }

  handleHalls(event: any) {
    const result = JSON.parse(event.data);
    mapStore.setHalls(result);
  }

  handleCrematoriums(event: any) {
    const result = JSON.parse(event.data);
    this.setCrematoriums(result);
  }

  handleCremations(event: any) {
    const result = JSON.parse(event.data);
    cremationStore.setCremations(result);
  }

  handleOpen() {
    this.setIsConnectionEstablished(true);
  }

  handleClosedDays(event: any) {
    const result = JSON.parse(event.data);
    closedDayStore.setClosedDay(result);
  }
}

export default new GlobalAppStore();
