import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import InfoPanel from './components/info-panel';
import FilterPanel from './components/filter-panel';
import ListDocuments from './components/list-documents';
import SortingTable from './components/sorting-table';

import cremationStore from 'src/stores/sorting-center/cremation-store';

import './sorting-center-page.scss';

const SortingCenterPage = observer(() => {
  const isDayClosed = cremationStore.isDayClosed;
  const [isListDocumentsCollapsed, setIsListDocumentsCollapsed] = React.useState(false);

  const onClick = () => setIsListDocumentsCollapsed((prev) => !prev);

  const classes = classNames(
    'sorting-center-page',
    isDayClosed && 'sorting-center-page_day-closed'
  );

  return (
    <div className={classes}>
      <FilterPanel />
      <SortingTable />
      {isDayClosed ? (
        <ListDocuments isCollapsed={isListDocumentsCollapsed} onClick={onClick} />
      ) : (
        <InfoPanel />
      )}
    </div>
  );
});

export default SortingCenterPage;
