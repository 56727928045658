import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import closedDayStore from 'src/stores/sorting-center/closed-day-store';
import cremationStore from 'src/stores/sorting-center/cremation-store';
import filtersStore from 'src/stores/sorting-center/filters-store';
import globalAppStore from 'src/stores/global-app-store';
import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import { getFormattedDate } from 'src/utils';
import { DATE_FORMAT, TOMORROW_ID } from 'src/constants';

import './info-panel.scss';

const InfoPanel = observer(() => {
  const data = {
    forTomorrow: cremationStore.getCremationCountByCrematorium(TOMORROW_ID),
    listCrematories: globalAppStore.crematoriums
      .filter(({ id }) => id !== TOMORROW_ID)
      .map(({ id, name }) => ({ name, count: cremationStore.getCremationCountByCrematorium(id) })),
    totalCountBodies: cremationStore.totalCremationCount,
  };

  const handleButtonClick = () => {
    popupStore.showPopup(POPUP_NAMES.CLOSE_DAY_POPUP, {
      day: getFormattedDate(filtersStore.date, DATE_FORMAT),
      data,
      onSubmit: () => closedDayStore.closeDay(),
    });
  };

  return (
    <div className="info-panel">
      <ul className="info-panel__list">
        {globalAppStore.crematoriumItems.map(({ id, name }) => (
          <li key={id} className="info-panel__list-item">
            <span className="info-panel__label">{`${
              id === TOMORROW_ID ? 'На завтра' : name
            }:`}</span>
            <span className="info-panel__value">
              {cremationStore.getCremationCountByCrematorium(id)}
            </span>
          </li>
        ))}
        <li className="info-panel__list-item info-panel__list-item_red">
          <span className="info-panel__label">Не отсортировано:</span>
          <span className="info-panel__value">{cremationStore.unsortedCremationCount}</span>
        </li>
      </ul>
      <Button
        className="info-panel__button"
        isDisabled={!cremationStore.isAllCremationSorted}
        label="Закрыть день и сформировать акты"
        onClick={handleButtonClick}
        theme="ghost"
      />
    </div>
  );
});

export default InfoPanel;
