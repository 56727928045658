import React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Button from 'src/components/ui/button';

import popupStore, { POPUP_NAMES } from 'src/stores/popups/popup-store';

import useOnClickOutside from 'src/hooks/use-on-click-outside';

import '../popup.scss';

const CLOSE_DAY_POPUP = POPUP_NAMES.CLOSE_DAY_POPUP;

export const CloseDayPopup = observer(() => {
  const closeDayPopupRef = React.useRef<HTMLDivElement | null>(null);

  const { isVisible, data, day, onSubmit } = popupStore.getPopupState(CLOSE_DAY_POPUP);

  const closePopup = () => {
    popupStore.hidePopup(CLOSE_DAY_POPUP);
  };

  const handleSubmit = () => {
    closePopup();
    onSubmit?.();
  };

  useOnClickOutside(closeDayPopupRef, closePopup, !isVisible);

  return (
    <div
      className={classNames('popup popup_close-day', isVisible && 'popup_visible')}
      ref={closeDayPopupRef}
    >
      <div className="popup__title-wrapper">
        <h3 className="popup__title">{`Вы зыкрываете день ${day}`}</h3>
      </div>

      <ul className="popup__items-wrapper">
        <li className="popup__big-item">Количество тел: {data?.totalCountBodies}</li>

        {data?.listCrematories?.map((crematory: any) => (
          <li className="popup__item" key={crematory.name}>
            {crematory.name}: <span className="popup__item_bold">{crematory.count}</span>
          </li>
        ))}

        <li className="popup__item">
          На завтра: <span className="popup__item_bold">{data?.forTomorrow ?? ''}</span>
        </li>
      </ul>

      <div className="popup__actions">
        <Button label="Подтвердить" onClick={handleSubmit} theme="filled" />

        <Button
          className="popup__cancel-button"
          label="Отмена"
          onClick={closePopup}
          theme="borderless"
        />
      </div>
    </div>
  );
});

export default CloseDayPopup;
