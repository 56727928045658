import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { IPerson } from 'src/interfaces/person.interface';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export function getParsedTime(date: string) {
  return dayjs.utc(date).tz('Europe/Moscow').format(TIME_FORMAT);
}

export function getParsedDate(date: string) {
  return dayjs.utc(date).format(DATE_FORMAT);
}

export function getLifeDates(deceased: IPerson | null) {
  const birthDate = deceased?.birthDate ? getParsedDate(deceased.birthDate) : '';
  const deathDate = deceased?.deathDate ? getParsedDate(deceased.deathDate) : '';
  return `${birthDate} \u{2012} ${deathDate}`;
}

export function isBeforeISODate(firstDate: string, secondDate: string = dayjs().toISOString()) {
  return dayjs(getParsedDate(firstDate), DATE_FORMAT).isBefore(
    dayjs(getParsedDate(secondDate), DATE_FORMAT),
    'day'
  );
}

export function isBeforeCurrentDate(date: string) {
  return dayjs(date, DATE_FORMAT).isBefore(
    dayjs(getParsedDate(dayjs().toISOString()), DATE_FORMAT),
    'day'
  );
}

export function getFormattedDate(date: string, format: string) {
  return dayjs(date).format(format);
}

export function getDifferenceDay(firstDate: string, secondDate: string) {
  return dayjs(getParsedDate(firstDate), DATE_FORMAT).diff(
    dayjs(getParsedDate(secondDate), DATE_FORMAT),
    'day'
  );
}
