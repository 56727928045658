import { makeObservable, observable, computed, reaction } from 'mobx';
import BasePopupStore from './base-popup-store';

export const POPUP_NAMES = {
  CLOSE_DAY_POPUP: 'closeDay',
  SELECT_WORKERS_POPUP: 'selectWorkers',
};

class PopupStore extends BasePopupStore {
  closeDayPopupState = this.getInitialPopupState();
  selectWorkersPopupState = this.getInitialPopupState();

  constructor() {
    super();

    makeObservable(this, {
      closeDayPopupState: observable,
      isPopupOpen: computed,
      selectWorkersPopupState: observable,
    });

    reaction(
      () => this.isPopupOpen,
      (isVisiblePopupShadow) => this.handlePopupShadow(isVisiblePopupShadow)
    );
  }

  get isPopupOpen() {
    return Object.values(POPUP_NAMES).some((popupName) => {
      return this.getPopupState(popupName).isVisible;
    });
  }

  handlePopupShadow(isVisible: boolean) {
    const popupShadow = document.querySelector('.app__shadow');

    if (!popupShadow) {
      return;
    }

    if (isVisible) {
      popupShadow.classList.add('app__shadow_visible');
    } else {
      popupShadow.classList.remove('app__shadow_visible');
    }
  }
}

export default new PopupStore();
