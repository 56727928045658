import React from 'react';
import { observer } from 'mobx-react-lite';

import MultiSelect from 'src/components/ui/multi-select';

import globalAppStore from 'src/stores/global-app-store';
import filtersStore from 'src/stores/sorting-center/filters-store';

import { ICrematorium } from 'src/interfaces/crematorium.interface';
import { SelectOption } from 'src/interfaces/select-option.interface';

const FilterPanelCrematories = observer(() => {
  return (
    <section className="filter-panel__section filter-panel__section_crematories">
      <h2 className="filter-panel__section-title">Направление</h2>

      <MultiSelect
        items={mapItems(globalAppStore.crematoriumItems)}
        label="Крематорий"
        onChange={filtersStore.setSelectedCrematoriesIds}
        value={filtersStore.selectedCrematoriesIds}
      />
    </section>
  );
});

function mapItems(items: ICrematorium[]): SelectOption[] {
  return items.map(({ id, name }) => ({ id, value: name }));
}

export default FilterPanelCrematories;
