import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';

import Input from 'src/components/ui/input';
import Radiobox from 'src/components/ui/radiobox';

import { DateFiltersProps } from './date-filters.props';
import { FILTERS_PERIODS, DATE_MASK, DATE_MASK_PLACEHOLDER } from 'src/constants';

import './date-filters.scss';

const DateFilters = observer(({ className, store }: DateFiltersProps) => (
  <div className={classNames('date-filters', className)}>
    {FILTERS_PERIODS.map((label, idx) => (
      <Radiobox
        key={idx}
        idx={`date_filters_${idx}`}
        label={label}
        name="period"
        isChecked={label === store.checkedPeriod}
        onChange={store.setCheckedPeriod}
      />
    ))}
    <Input
      id={uuidv4()}
      isDisabled={store.isCustomDateInputDisabled}
      className="date-filters__input"
      label="Укажите дату"
      mask={DATE_MASK}
      maskPlaceholder={DATE_MASK_PLACEHOLDER}
      {...store.getCustomDateInputProps()}
      {...store.getCustomDateValidationProps()}
    />
  </div>
));

export default DateFilters;
