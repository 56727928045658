import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import SortingTableRow from '../sorting-table-row';
import TooltipTrigger from 'src/components/ui/tooltip-trigger';
import EmptyData from 'src/components/common/empty-data';
import { ReactComponent as EmergencyIcon } from 'src/assets/icons/emergency.svg';
import { ReactComponent as ScarfIcon } from 'src/assets/icons/scarf.svg';

import globalAppStore from 'src/stores/global-app-store';
import filtersStore from 'src/stores/sorting-center/filters-store';
import cremationStore from 'src/stores/sorting-center/cremation-store';

import './sorting-table.scss';

const SortingTable = observer(() => {
  const isConnectionEstablished = globalAppStore.isConnectionEstablished;

  React.useEffect(() => {
    if (isConnectionEstablished) {
      filtersStore.initDate();
    }
  }, [isConnectionEstablished]);

  const renderCrematoriumsAbbrCell = () => (
    <div className="sorting-table__cremations_abbr">
      {globalAppStore.crematoriumItems.map((crematorium) => (
        <span key={crematorium.id}>{crematorium.abbreviation}</span>
      ))}
    </div>
  );

  const isEmptyData = !cremationStore.filteredCremations.length;
  const pageClasses = classNames('sorting-table', isEmptyData && 'sorting-table_empty');
  const tableClasses = classNames(
    'table sorting-table__table',
    cremationStore.isDayClosed && 'sorting-table__table_disabled'
  );

  return (
    <div className={pageClasses}>
      <div className="sorting-table__table-wrapper scrollbar">
        <table className={tableClasses}>
          <thead className="table__header">
            <tr className="table__row">
              <th className="table__header-cell">Дата</th>
              <th className="table__header-cell table__header-cell_cremated">Кремируемый</th>
              <th className="table__header-cell">Прощальный зал</th>
              <th className="table__header-cell">Комментарий</th>
              <th className="table__header-cell">{renderCrematoriumsAbbrCell()}</th>
              <th className="table__header-cell table__header-cell_last">
                <TooltipTrigger isHideOnScroll={false} text="Срочный">
                  <EmergencyIcon className="control-room-page__icon" />
                </TooltipTrigger>
              </th>
            </tr>
          </thead>
          {!isEmptyData && (
            <tbody className="table__body">
              {cremationStore.filteredCremations.map((cremation) => {
                return <SortingTableRow key={cremation.id} cremation={cremation} />;
              })}
            </tbody>
          )}
        </table>
      </div>

      {isEmptyData && !cremationStore.isLoading ? (
        <EmptyData
          className="control-room-page__empty-data"
          icon={<ScarfIcon width={32} height={32} />}
          text="Попробуйте изменить параметры фильтрации"
          title="Нет записей"
        />
      ) : null}
    </div>
  );
});

export default SortingTable;
