import React from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import InputPlaceholder from 'src/components/ui/input-placeholder';
import Label from 'src/components/ui/label';
import TextError from 'src/components/ui/text-error';

import { InputProps } from './input.props';

import './input.scss';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      icon,
      id,
      isDisabled = false,
      isValid = true,
      isOutlined = false,
      label,
      mask = '',
      maskPlaceholder,
      onBlur,
      onChange,
      onFocus,
      onKeyPress,
      placeholder,
      rows,
      textError = '',
      type = 'text',
      value,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);

    React.useEffect(() => {
      setIsFocused(false);
    }, [isOutlined]);

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur?.();
    };

    const handleChange = (
      event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement>
    ) => onChange(event.currentTarget.value);

    const renderInputMask = () => (
      <>
        <InputMask
          className="input__field"
          id={id}
          disabled={isDisabled}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          type={type}
          value={value}
        />
        {icon && <span className="input__icon">{icon}</span>}
      </>
    );

    const renderInput = () => {
      return rows ? (
        <textarea
          className="scrollbar"
          disabled={isDisabled}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          rows={rows}
          value={value}
        />
      ) : (
        <>
          <input
            className="input__field"
            disabled={isDisabled}
            id={id}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyPress={onKeyPress}
            ref={ref}
            type={type}
            value={value}
          />
          {icon && <span className="input__icon">{icon}</span>}
        </>
      );
    };

    const inputClasses = classNames('input', className, {
      input_disabled: isDisabled,
      input_focus: isFocused,
      input_icon: !!icon,
      input_invalid: !isValid && !isFocused,
      input_label: !!label,
      input_mask: !!mask,
      input_multi: !!rows,
      input_outlined: isOutlined,
      input_placeholder: !!placeholder,
      input_search: type === 'search',
    });

    const labelClasses = classNames('input__label', {
      label_disabled: isDisabled,
      label_invalid: !isValid && !isFocused,
      label_placeholder: !value && !isFocused,
    });

    return (
      <div className={inputClasses}>
        {label ? <Label id={id} label={label} className={labelClasses} /> : null}
        {!label && placeholder ? (
          <InputPlaceholder placeholder={placeholder} hide={isFocused || !!value} />
        ) : null}
        {mask ? renderInputMask() : renderInput()}
        {!isValid && !isFocused && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    );
  }
);

export default Input;
