import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import filtersStore from './filters-store';
import globalAlertStore from 'src/stores/global-alert-store';
import globalAppStore from 'src/stores/global-app-store';

import { IClosedCrematorium } from 'src/interfaces/closed-crematorium.interface';
import { IClosedDayApi } from 'src/interfaces/closed-day-api.interface';
import { IWorkerApi } from 'src/interfaces/worker-api.interface';
import { SelectOption } from 'src/interfaces/select-option.interface';
import { downloadFile, getFormattedDate } from 'src/utils';
import { doGet, doPost } from 'src/api';
import { DATE_FORMAT_API } from 'src/constants';
import { AlertType } from 'src/components/ui/alert';

class ClosedDayStore {
  date: string | null = null;
  dayId: string | null = null;
  listWorkers: SelectOption[] = [];
  listClosedCrematoriums: IClosedCrematorium[] = [];

  constructor() {
    makeAutoObservable(this);

    this.getExport = this.getExport.bind(this);
  }

  setListWorkers(listWorkers: IWorkerApi[]) {
    this.listWorkers = getWorkersFromApi(listWorkers);
  }

  setDayId(dayId: string | null) {
    this.dayId = dayId;
  }

  setDate(date: string | null) {
    this.date = date;
  }

  setClosedDay({ id, crematoriums = [], date, workers = [] }: IClosedDayApi) {
    const listClosedCrematoriums = crematoriums
      .map(({ id, cremations_count, workers }) => {
        const name =
          globalAppStore.crematoriums.find((crematorium) => crematorium.id === id)?.name ?? '';

        return { id, cremationsCount: cremations_count, name, workers: getWorkersFromApi(workers) };
      })
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        return -1;
      });

    this.setDate(date);
    this.setDayId(id);
    this.setListClosedCrematoriums(listClosedCrematoriums);
    this.setListWorkers(workers);
  }

  setListClosedCrematoriums(listClosedCrematoriums: IClosedCrematorium[]) {
    this.listClosedCrematoriums = listClosedCrematoriums;
  }

  async closeDay() {
    globalAppStore.setIsLoading(true);

    try {
      const date = getFormattedDate(filtersStore.date, DATE_FORMAT_API);
      await doPost(`/closed_days`, { date });
    } catch (e) {
      console.log('e', e);
    }

    globalAppStore.setIsLoading(false);
  }

  async addWorkers(crematoriumId: string, listSelectedWorkersIdx: string[]) {
    globalAppStore.setIsLoading(true);

    try {
      const data = {
        crematorium: crematoriumId,
        workers: listSelectedWorkersIdx,
      };

      await doPost(`/closed_days/${this.dayId}/workers`, data);
    } catch (e) {
      console.log('e', e);
    }

    globalAppStore.setIsLoading(false);
  }

  async getExport(crematoriumId: string, name: string) {
    try {
      const response = await doGet(`/closed_days/${this.dayId}/docs/${crematoriumId}`, {
        responseType: 'blob',
      });

      if (response.data?.size > 0) {
        const fileName = `${name.split(' ').join('_')}_${getFormattedDate(
          this.date ?? '',
          'YYYY_MM_DD'
        )}`;

        downloadFile(response.data, fileName);
      } else {
        globalAlertStore.addAlert({
          id: uuidv4(),
          desc: '',
          timeout: 10000,
          title: 'Нет данных для экспорта',
          type: AlertType.error,
        });
      }
    } catch (e) {
      console.log('e', e);
    }
  }
}

function getWorkersFromApi(workers: IWorkerApi[]) {
  return workers.map(({ id, short_name }) => ({ id, value: short_name }));
}

export default new ClosedDayStore();
